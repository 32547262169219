import { useStaticQuery, graphql, Link } from "gatsby";
import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";
import SecondaryHero from "../../components/secondary-hero";
import EventSection from "../../components/event-section";
import CorporateForm from "../../components/corporate-membership-form";
import { GatsbyImage } from "gatsby-plugin-image";
import { Button } from "react-bootstrap";

const CorporateSupportersPage = ({ transitionStatus }) => {
	const data = useStaticQuery(graphql`
		query {
			heroImgMain: wpMediaItem(title: { eq: "Home Hero Main" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			wpPageContent(id: { eq: "cG9zdDoxOTU4" }) {
				content
				corporateSupporterFields {
					supporters {
						websiteUrl
						websiteName
						name
						description
						image {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 50
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Membership",
				item: {
					url: `${siteUrl}/membership`,
					id: `${siteUrl}/membership`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Our Corporate Supporters",
				item: {
					url: `${siteUrl}/membership/our-corporate-supporters`,
					id: `${siteUrl}/membership/our-corporate-supporters`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Our Corporate Supporters | Friends of Eastbourne Hospital"
				description="Our Corporate Supporters"
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/membership/our-corporate-supporters`,
					title: "Our Corporate Supporters | Friends of Eastbourne Hospital",
					description: "Our Corporate Supporters",
					images: [
						{
							url: `${data.heroImgMain?.localFile.publicURL}`,
							width: `${data.heroImgMain?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImgMain?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImgMain?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section className="pb-5">
					<SecondaryHero
						title="Our Corporate Supporters"
						description={
							<span>
								The Friends of Eastbourne Hospital are very fortunate to receive
								loyal community support.
								<br className="d-none d-md-block" /> This is reinforced by the
								local businesses who have chosen to become corporate supporters.
								<br className="d-none d-md-block" />
								Please do read about them and, in turn, use their services in
								order to support us.
							</span>
						}
					/>
				</section>
				<section className="py-5 py-lg-7">
					<Container fluid className="px-md-4 px-3">
						<Row className="justify-content-center">
							<Col className="page-content team-page">
								<Row className="gx-5 gy-6">
									{data.wpPageContent.corporateSupporterFields.supporters.map(
										(member, index) => (
											<SupporterCard key={index} member={member} />
										)
									)}
								</Row>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="pb-8">
					<Container>
						<Row>
							<Col className="page-content becoming-a-volunteer">
								{parse(data.wpPageContent.content)}

								<Button
									as={Link}
									to="/membership/corporate-membership"
									variant="primary"
									className="text-center py-3 w-100 w-md-auto mt-3 text-white px-5"
								>
									<span className="text-white">Sign Up</span>
								</Button>
							</Col>
						</Row>
					</Container>
				</section>
			</Layout>
		</>
	);
};

const SupporterCard = ({ member }) => {
	const [isReadMore, setIsReadMore] = useState(true);
	const toggleReadMore = () => {
		setIsReadMore(!isReadMore);
	};

	const truncateText = (text) => {
		if (!text) return "";
		const paragraphs = text.split("</p>");
		if (paragraphs.length <= 1) {
			return text;
		}
		return paragraphs[0] + "</p>";
	};

	const descriptionToShow = isReadMore
		? parse(truncateText(member.description))
		: parse(member.description);

	return (
		<Col className="d-flex flex-column" md={6} lg={4}>
			<div
				className="image-container d-flex align-items-center justify-content-center"
				style={{ height: "200px" }}
			>
				<GatsbyImage
					objectFit="contain"
					style={{ maxWidth: "100%", height: "100%", objectFit: "contain" }}
					image={member.image?.localFile.childImageSharp.gatsbyImageData}
					alt={member.image?.localFile.altText}
				/>
			</div>
			<div>
				<h3 className="fs-3 mt-5">{member.name}</h3>
				<a
					className="fs-5 lato-regular text-black"
					href={member.websiteUrl}
					target="_blank"
					rel="noreferrer"
				>
					{member.websiteName}
				</a>
				<div style={{ fontSize: "90%" }}>
					{descriptionToShow}
					{member.description &&
						member.description.split("</p>").length > 1 && (
							<>
								<span
									style={{ cursor: "pointer" }}
									onClick={toggleReadMore}
									className="read-or-hide nav-link py-0 mt-0"
								>
									{isReadMore ? "Read more" : "Show less"}
								</span>
							</>
						)}
				</div>
			</div>
		</Col>
	);
};

export default CorporateSupportersPage;
